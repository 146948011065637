import type { HubCommentDashboard, HubDashboard, HubDashboardFilter } from '~/types/configuration'

export const useDashboardStore = defineStore('dashboard', () => {
  const dashboardList: Ref<Array<HubDashboard | HubCommentDashboard>> = ref([])
  const currentDashboardId = ref<string>()

  const allDashboards: ComputedRef<Array<HubDashboard | HubCommentDashboard>> = computed(() =>
    dashboardList.value.sort((a, b) => {
      if (a.type === 'comments' && b.type !== 'comments') {
        return 1
      }
      if (b.type === 'comments' && a.type !== 'comments') {
        return -1
      }
      return 0
    })
  )
  const defaultDashboards: ComputedRef<Array<HubDashboard>> = computed(
    () => dashboardList.value.filter(x => x.type === 'default' || !x.type) as Array<HubDashboard>
  )
  const commentDashboards: ComputedRef<Array<HubCommentDashboard>> = computed(() =>
    dashboardList.value.filter(x => x.type === 'comments')
  )
  const currentDashboard = computed(() => dashboardList.value.find(x => x.id === currentDashboardId.value))
  const currentDashboardFilters = computed(() => {
    const dashboard = dashboardList.value.find(x => x.id === currentDashboardId.value)
    return dashboard?.filter || []
  })

  function setCurrentDashboardId(dashboardId: string) {
    currentDashboardId.value = dashboardId
  }

  async function fetchDashboards(forceUpdate: boolean = false) {
    if (dashboardList.value.length && !forceUpdate) {
      return dashboardList.value
    }

    const data = (await $hubFetch('api/v4/dashboards')) as Array<HubDashboard>

    if (!data) {
      throw new Error('Dashboards Not Loaded')
    }

    dashboardList.value = data.sort((a: HubDashboard, b: HubDashboard) => a.ordinal - b.ordinal)
  }

  async function fetchDashboard(dashboardId: string, forceUpdate: boolean = false) {
    const dashboard = dashboardList.value.find(x => x.id === dashboardId)
    if (dashboard?.config && !forceUpdate) {
      return dashboard
    }

    const data = (await $hubFetch(`api/v4/dashboards/${dashboardId}`)) as HubDashboard

    if (!data) {
      throw createError({ statusCode: 404, statusMessage: 'Dashboard Not Found', fatal: true })
    }

    updateDashboard(dashboardId, data)
    return data
  }

  async function fetchDashboardFilters(dashboardId: string, forceUpdate: boolean = false) {
    const dashboard = dashboardList.value.find(x => x.id === dashboardId)
    if (dashboard?.filter && !forceUpdate) {
      return dashboard.filter
    }

    const { data, error } = await useHubFetch<Array<HubDashboardFilter>>(`api/v4/dashboards/${dashboardId}/filters`)

    if (!data) {
      throw new Error(error.value?.statusMessage)
    }

    updateDashboard(dashboardId, data.value, true)
    return data.value
  }

  async function updateDashboard(
    dashboardId: string,
    updateBody: HubDashboard | Array<HubDashboardFilter>,
    filter: boolean = false
  ) {
    if (!dashboardList.value || !dashboardList.value.length) {
      await fetchDashboards()
    }

    const dashboardIndex = dashboardList.value.findIndex(x => x.id === dashboardId)

    if (dashboardIndex === -1) {
      if (filter) {
        throw new Error('updateDashboard: Dashboard Not Found')
      }

      dashboardList.value.push(updateBody as HubDashboard)
      return
    }

    if (filter) {
      dashboardList.value[dashboardIndex].filter = updateBody as Array<HubDashboardFilter>
      return dashboardList.value[dashboardIndex]
    }

    dashboardList.value[dashboardIndex] = updateBody as HubDashboard
  }

  function removeDashboard(dashboardId: string) {
    const itemIndex = dashboardList.value.findIndex(x => x.id === dashboardId)
    if (itemIndex === -1) {
      return
    }
    dashboardList.value.splice(itemIndex, 1)
  }

  return {
    // only used internally but need to be returned
    dashboardList,
    currentDashboardId,

    // public things
    currentDashboard,
    currentDashboardFilters,
    allDashboards,
    defaultDashboards,
    commentDashboards,

    fetchDashboard,
    fetchDashboards,
    fetchDashboardFilters,
    updateDashboard,
    removeDashboard,
    setCurrentDashboardId
  }
})
